.github {
    color: black;
}

.linkedin {
    color: black;
}

.email {
    color: black;
}
.resume {
    color: black;
}